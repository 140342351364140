import {
  useBuildAEXUrl,
  AEXCategory,
  AEXSection,
} from './../hooks/useBuildAEXUrl';
import { useCallback, useMemo } from 'react';
import { Locale, useLanguage } from '@ae/i18n';
import { useGetDomain } from '@ae/shared';
import { LinkGetter, useLink } from './../hooks/useLink';

export enum AEXLink {
  Activities,
  Hikes,
  ExperiencesNearRental,
  Search,
  WalksNearRental,
  SitytrailDetails,
}

export const useGetAEXLink = () => {
  const lng = useLanguage();
  const { getBeProdDomain } = useGetDomain();
  const { buildUrl: buildAEXUrl } = useBuildAEXUrl();

  const beProdDomain = useMemo(() => {
    switch (lng) {
      case 'de':
      case 'en':
        return `${getBeProdDomain(Locale.En)}`;
      case 'fr':
        return `${getBeProdDomain(Locale.Fr)}`;
      case 'nl':
      default:
        return `${getBeProdDomain(Locale.Nl)}`;
    }
  }, [getBeProdDomain, lng]);

  const getLink = useCallback(
    (link: AEXLink): LinkGetter => {
      switch (link) {
        case AEXLink.Activities:
          return { href: `${beProdDomain}/experience` };
        case AEXLink.Hikes:
          return {
            href: () => {
              switch (lng) {
                case 'en':
                case 'de':
                  return `${beProdDomain}/experience/hike`;
                case 'fr':
                  return `${beProdDomain}/experience/balade`;
                case 'nl':
                default:
                  return `${beProdDomain}/experience/wandeling`;
              }
            },
          };
        case AEXLink.ExperiencesNearRental:
          return {
            href: (lat: string, long: string, category: AEXCategory) =>
              buildAEXUrl({
                domain: beProdDomain,
                section: AEXSection.Experience,
                lng,
                lat,
                long,
                category,
                rate: 60,
              }),
          };
        case AEXLink.Search:
          return {
            href: (
              lat: string,
              long: string,
              rate: number,
              category: AEXCategory
            ) =>
              buildAEXUrl({
                domain: beProdDomain,
                section: AEXSection.Experience,
                lng,
                lat,
                long,
                rate,
                category,
              }),
          };
        case AEXLink.WalksNearRental:
          return {
            href: (lat: string, long: string) =>
              buildAEXUrl({
                domain: beProdDomain,
                section: AEXSection.Walk,
                lng,
                lat,
                long,
                rate: 60,
              }),
          };
        case AEXLink.SitytrailDetails:
          return {
            href: () => {
              switch (lng) {
                case 'en':
                case 'de':
                  return `${beProdDomain}/experience/what-is-the-sitytrail-advantage-at-ardennes-etape`;
                case 'fr':
                  return `${beProdDomain}/experience/news/quest-ce-que-lavantage-sitytrail`;
                case 'nl':
                default:
                  return `${beProdDomain}/experience/news/wat-het-sitytrail-voordeel`;
              }
            },
          };
        default:
          throw Error('link not found');
      }
    },
    [buildAEXUrl, beProdDomain, lng]
  );

  return useLink<AEXLink>(getLink);
};
