import { Alert as BaseAlert, Text } from '@chakra-ui/react';
import { UIAlertIconDisplay } from '@ae/shared-ui';
import { AlertIcon } from '@ae/icons';

type Props = {
  description: string;
  status: 'warning' | 'success' | 'error' | 'info';
  displayIcon: boolean;
};

export const UIAlertMessage = ({ description, status, displayIcon }: Props) => {
  let icon;
  let bg;
  let color;
  let borderColor;

  if (status === 'warning') {
    bg = 'warning.100';
    borderColor = 'warning.400';
    color = 'warning.900';
  }
  if (status === 'success') {
    bg = 'success.100';
    borderColor = 'success.400';
    color = 'success.900';
  }
  if (status === 'error') {
    bg = 'error.100';
    borderColor = 'error.400';
    color = 'error.900';
  }
  if (displayIcon) {
    icon = (
      <UIAlertIconDisplay>
        <AlertIcon />
      </UIAlertIconDisplay>
    );
  }

  return (
    <BaseAlert
      bg={bg}
      border="2px solid"
      borderColor={borderColor}
      color={color}
      borderRadius="4px"
      mt="8px"
      p="10px"
      status={status}
      alignItems={{
        base: 'center',
      }}
      gap="5px"
    >
      {icon}
      <Text px="8px" color="ae.grey_400">
        {description}
      </Text>
    </BaseAlert>
  );
};
