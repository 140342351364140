import { Locale, useLanguage } from '@ae/i18n';
import { format, isSameDay, lastDayOfMonth, startOfMonth } from 'date-fns';
import { useCallback } from 'react';
import { StayDateAvailableJsonldReadStayDateAvailableStayType } from '@ae/data-access';
import {
  defaultSearchValues,
  SearchValues,
  stayTypeLabelsByLanguage,
  stayTypesByLabel,
} from '../types';
import { useRouter } from '@ae/shared';
import { useMr2Url } from './useMr2Url';
import { utcToZonedTime } from 'date-fns-tz';

const dogRegexByLanguage: { [x in Locale]: RegExp } = {
  [Locale.Fr]: /(\d+)-(\d+)_Chiens/,
  [Locale.Nl]: /(\d+)-(\d+)_Honden/,
  [Locale.En]: /(\d+)-(\d+)_Dogs/,
  [Locale.De]: /(\d+)-(\d+)_Hunden/,
};

const dogLabelsByLanguage = {
  [Locale.Fr]: 'Chiens',
  [Locale.Nl]: 'Honden',
  [Locale.En]: 'Dogs',
  [Locale.De]: 'Hunden',
};

const folderUrlByLanguage = {
  [Locale.Fr]: '_maisons-de-vacances-en-Ardenne',
  [Locale.Nl]: '_vakantiehuizen-in-de-Ardennen',
  [Locale.En]: '_holiday-Houses-in-the-Ardennes',
  [Locale.De]: '_ferienwohnungen-in-den-Ardennen',
};

const stayTypeRegex: { [x in Locale]: RegExp } = {
  [Locale.Fr]: new RegExp(
    `${Object.values(stayTypeLabelsByLanguage[Locale.Fr]).join('|')}`
  ),
  [Locale.Nl]: new RegExp(
    `${Object.values(stayTypeLabelsByLanguage[Locale.Nl]).join('|')}`
  ),
  [Locale.En]: new RegExp(
    `${Object.values(stayTypeLabelsByLanguage[Locale.En]).join('|')}`
  ),
  [Locale.De]: new RegExp(
    `${Object.values(stayTypeLabelsByLanguage[Locale.De]).join('|')}`
  ),
};

const generateDogsUrl = (dogs: number, language: Locale) => {
  const dogLabel = dogLabelsByLanguage[language];
  return `${dogs}-8_${dogLabel}`;
};

const parsePersons = (url: string) => {
  if (url.includes('1-99p')) {
    return null;
  }
  const regexPersons = /(\d+-\d+p|\d+p)/;
  const matchesPersons = url.match(regexPersons);
  if (matchesPersons && matchesPersons.length > 0) {
    const [people] = matchesPersons[0].split('p');
    const [minPeople] = people.split('-');
    return Number(minPeople);
  }
  return null;
};

const parseDogs = (url: string, language: Locale) => {
  const regexDogs = dogRegexByLanguage[language];
  const matchesDogs = url.match(regexDogs);
  if (matchesDogs) {
    const [, minDogs] = matchesDogs;
    return Number(minDogs);
  }
  return null;
};

export const isFullMonth = (startDate: Date, endDate: Date) =>
  isSameDay(startOfMonth(startDate), startDate) &&
  isSameDay(lastDayOfMonth(endDate), endDate);

export const useSearchUrl = () => {
  const language = useLanguage();
  const { pathname } = useRouter();
  const { parseMr2FiltersFromUrl, generateMr2FiltersUrl } = useMr2Url();

  // not used for now
  const parseSearchUrl = useCallback(
    (url?: string): SearchValues => {
      url = url ?? pathname;

      const searchParams: SearchValues = {
        ...defaultSearchValues,
      };

      // Parse persons
      const persons = parsePersons(url);
      if (persons !== null) {
        searchParams.composition = {
          persons,
          dogs: 0,
        };

        // Parse dogs
        const dogs = parseDogs(url, language as Locale);
        if (dogs !== null) {
          searchParams.composition.dogs = dogs;
        }
      }

      let parsedStartDate = null;
      let parsedEndDate = null;
      let parsedStayType = null;

      // Parse dates
      const regexInterval = /(\d{4}-\d{2}-\d{2})_to_(\d{4}-\d{2}-\d{2})/;
      const matchesInterval = url.match(regexInterval);
      if (matchesInterval) {
        const [, startDate, endDate] = matchesInterval;
        parsedStartDate = utcToZonedTime(
          new Date(startDate),
          'Europe/Brussels'
        );
        parsedEndDate = utcToZonedTime(new Date(endDate), 'Europe/Brussels');
      } else {
        const regexDate = /(\d{4}-\d{2}-\d{2})/;
        const matchesDate = url.match(regexDate);
        if (matchesDate) {
          const [date] = matchesDate;
          if (date !== '0000-00-00') {
            parsedStartDate = startOfMonth(
              utcToZonedTime(
                new Date(date.replace(/-00$/, '-01')),
                'Europe/Brussels'
              )
            );
            parsedEndDate = lastDayOfMonth(parsedStartDate);
          }
        }
      }

      const matchesStayType = url.match(stayTypeRegex[language as Locale]);
      if (matchesStayType) {
        parsedStayType =
          stayTypesByLabel[language as Locale][matchesStayType[0]];
      }
      if (parsedStartDate || parsedEndDate || parsedStayType) {
        if (parsedStartDate && parsedEndDate) {
          parsedStartDate?.setHours(0, 0, 0, 0);
          parsedEndDate?.setHours(0, 0, 0, 0);
        }
        searchParams.stayDate = {
          startDate: parsedStartDate,
          endDate: parsedEndDate,
          stayType: parsedStayType,
        };
      }

      // Parse locality
      const regexLocalityId = new RegExp(
        `(\\d+)${folderUrlByLanguage[language as Locale]}`
      );
      const matchesLocalityId = url.match(regexLocalityId);
      if (matchesLocalityId) {
        searchParams.localityId = Number(matchesLocalityId[1]) ?? null;
      }

      return searchParams;
    },
    [language]
  );

  const generateSearchUrl = useCallback(
    (searchParams: SearchValues, lng?: Locale) => {
      const urlParts = [];
      const composition = searchParams?.composition;
      const stayDate = searchParams?.stayDate;
      const localityId = searchParams?.localityId;

      lng = lng ?? (language as Locale);

      if (composition) {
        urlParts.push(`${composition.persons}p`);

        if (composition.dogs !== 0) {
          urlParts.push(generateDogsUrl(composition.dogs, lng));
        }
      } else {
        urlParts.push('1-99p');
      }

      if (stayDate) {
        if (stayDate.stayType) {
          urlParts.push(stayTypeLabelsByLanguage[lng][stayDate.stayType]);
        }

        if (stayDate.startDate && stayDate.endDate) {
          const formatDate = (date: Date) => format(date, 'yyyy-MM-dd');

          if (isFullMonth(stayDate.startDate, stayDate.endDate)) {
            urlParts.push(
              formatDate(stayDate.startDate).replace(/-01$/, '-00')
            );
          } else {
            urlParts.push(
              `${formatDate(stayDate.startDate)}_to_${formatDate(
                stayDate.endDate
              )}`
            );
          }
        } else {
          urlParts.push('0000-00-00');
        }
      } else {
        const dateUrl =
          stayTypeLabelsByLanguage[lng][
            StayDateAvailableJsonldReadStayDateAvailableStayType.wk
          ];
        urlParts.push(dateUrl);
        urlParts.push('0000-00-00');
      }

      if (localityId) {
        const folderUrl = folderUrlByLanguage[lng];
        urlParts.push(`${localityId}${folderUrl}`);
      }

      // Mr2 filters
      const mr2Filters = generateMr2FiltersUrl(parseMr2FiltersFromUrl(), lng);
      if (mr2Filters) {
        urlParts.push(mr2Filters);
      }

      return '/' + urlParts.join('/');
    },
    [language, pathname, parseMr2FiltersFromUrl, generateMr2FiltersUrl]
  );

  return {
    parseSearchUrl,
    generateSearchUrl,
  };
};
