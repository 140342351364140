import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  ModalContentProps,
  ModalProps,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  Box,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { ChevronLeftIcon } from '@ae/icons';
import { useViewport } from '../../providers';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  title?: string;
  children: ReactNode;
  dataTestId?: string;
  showCloseButton?: boolean;
  showBackButton?: boolean;
  onBackClick?: () => void;
  pb?: string;
};

export const UIModal = ({
  onClose,
  isOpen,
  title,
  children,
  dataTestId,
  showCloseButton = true,
  showBackButton,
  onBackClick,
  pb,
  ...props
}: Props & ModalProps & ModalContentProps) => {
  const { isMobile } = useViewport();

  if (isMobile) {
    return (
      <Drawer placement="bottom" isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay backgroundColor="rgba(0, 63, 53, 0.4)" />
        <DrawerContent
          boxShadow={{ base: 'base', md: 'none' }}
          borderRadius={{ base: '20px 20px 0px 0px', md: '0' }}
          borderStyle={{ lg: 'solid' }}
          borderColor={{ lg: 'ae.greyF1' }}
          data-testid={dataTestId}
          pb="20px"
        >
          {showCloseButton && (
            <DrawerCloseButton
              border={0}
              fontSize="14px"
              p="24px"
              top="10px"
              color="ae.green"
              outline="none"
              _hover={{ outline: 'none' }}
              _active={{ outline: 'none' }}
              _focus={{ outline: 'none' }}
              style={{ WebkitTapHighlightColor: 'transparent' }}
            />
          )}
          <DrawerHeader maxW="calc(100% - 40px)">
            <HStack alignItems="flex-start">
              {showBackButton && (
                <Box>
                  <IconButton
                    aria-label="back"
                    icon={<ChevronLeftIcon />}
                    color="ae.green"
                    w="full"
                    borderRadius="0"
                    variant="unstyled"
                    size="sm"
                    fontWeight={400}
                    onClick={onBackClick}
                    outline="none"
                    _hover={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                    _focus={{ outline: 'none' }}
                    style={{ WebkitTapHighlightColor: 'transparent' }}
                  />
                </Box>
              )}

              <Text
                py="3px"
                fontSize="22px"
                as="span"
                color="ae.green"
                fontFamily="heading"
                fontWeight={400}
                flexGrow={1}
              >
                {title}
              </Text>
            </HStack>
          </DrawerHeader>
          <DrawerBody>{children}</DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  }

  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} {...props}>
      <ModalOverlay backgroundColor="rgba(0, 63, 53, 0.4)" />
      <ModalContent
        data-testid={dataTestId}
        mt="80px"
        maxW="475px"
        borderTopLeftRadius="8px"
        borderTopRightRadius="8px"
        borderBottomLeftRadius="8px"
        borderBottomRightRadius="8px"
        bgColor="none"
        {...props}
      >
        <ModalCloseButton
          color="ae.green"
          p="20px"
          top="15px"
          fontSize="12px"
          outline="none"
          _hover={{ outline: 'none' }}
          _active={{ outline: 'none' }}
          _focus={{ outline: 'none' }}
        />
        <ModalHeader p="20px" pr="70px">
          {title && (
            <Text
              fontWeight={400}
              color="ae.green"
              fontSize="22px"
              fontFamily="heading"
            >
              {title}
            </Text>
          )}
        </ModalHeader>

        <ModalBody
          pt="20px"
          pb={pb ?? '40px'}
          borderBottomLeftRadius="8px"
          borderBottomRightRadius="8px"
        >
          {children}
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  );
};
