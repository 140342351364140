import { useContextualDataLayer } from './useContextualDataLayer';
import { useSearchUrl } from './useSearchUrl';
import { useTag } from '@ae/tagging';
import { useSearchValuesInCookie } from './useSearchValuesInCookie';
import { useState } from 'react';
import { useLocalityInCookie } from './useLocalityInCookie';
import { LocalityJsonldReadLocality } from '@ae/data-access';
import { SearchValues } from '../types';
import { AELink, useGetAELink, useRouter } from '@ae/shared';

export const useSearch = ({
  localities,
}: {
  localities: LocalityJsonldReadLocality[] | null | undefined;
}) => {
  const { pathname } = useRouter();
  const { getLinkHref } = useGetAELink();
  const trackEvent = useTag();
  const { updateDataLayer, getDataLayer } = useContextualDataLayer();
  const { setLocality: setLocalityInCookie } = useLocalityInCookie();
  const { generateSearchUrl, parseSearchUrl } = useSearchUrl();
  const {
    searchValues: searchValuesFromCookie,
    setSearchValues: setSearchValuesIntoCookie,
  } = useSearchValuesInCookie();
  const [searchValues, setSearchValues] = useState<SearchValues>(() => {
    if (
      pathname?.includes('/recherche') || // French
      pathname?.includes('/zoeken') || // Dutch
      pathname?.includes('/suchen') || // German
      pathname?.includes('/search') // English
    ) {
      const searchValues = parseSearchUrl();
      setSearchValuesIntoCookie(searchValues);
      return { ...searchValues };
    } else {
      return { ...searchValuesFromCookie };
    }
  });

  const updateSearch = (newSearch: SearchValues) => {
    if (localities) {
      if (newSearch.localityId) {
        const locality = localities.find((l) => l.id === newSearch.localityId);
        if (locality) {
          setLocalityInCookie(locality);
        }
      } else {
        setLocalityInCookie(null);
      }
    }
    setSearchValues(newSearch);
    updateDataLayer(newSearch);
  };

  const makeSearch = () => {
    trackEvent({
      ga4: {
        event: 'search',
        action: 'submit',
        filter_level: 'mr1',
        ...getDataLayer(searchValues),
      },
    });
    setSearchValuesIntoCookie(searchValues);

    window.location.href = `${getLinkHref(AELink.Search)}${generateSearchUrl(
      searchValues
    )}`;
  };

  return {
    searchValues,
    updateSearch,
    makeSearch,
  };
};
