import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  addMonths,
  isSameDay,
  isSameMonth,
  lastDayOfMonth,
  parseISO,
  startOfMonth,
} from 'date-fns';
import { ChevronLeftIcon, ChevronRightIcon } from '@ae/icons';
import {
  StayDateAvailableJsonldReadStayDateAvailable,
  StayDateAvailableJsonldReadStayDateAvailableStayType,
} from '@ae/data-access';
import {
  UIButton,
  UIButtonProps,
  UISelectBox,
  useViewport,
} from '@ae/shared-ui';
import { CalendarDay } from './CalendarDay';
import {
  capitalizeFirstLetter,
  useTranslation,
  useCalendar,
  formatToBrusselsTz,
} from '@ae/shared';
import { Locale, useLanguage } from '@ae/i18n';
import { StayDate, stayTypeLabels } from '@ae/shared-comp';

const findStayDateWithOldestStartDate = (
  stayDates: StayDateAvailableJsonldReadStayDateAvailable[]
) => {
  if (!stayDates.length) {
    return null;
  }
  return stayDates.reduce((acc, stayDate) => {
    if (!acc) {
      return stayDate;
    }
    return new Date(stayDate.date.startDate) < new Date(acc.date.startDate)
      ? stayDate
      : acc;
  });
};

const findStayDateWithLatestEndDate = (
  stayDates: StayDateAvailableJsonldReadStayDateAvailable[]
) => {
  if (!stayDates.length) {
    return null;
  }
  return stayDates.reduce((acc, stayDate) => {
    if (!acc) {
      return stayDate;
    }
    return new Date(stayDate.date.endDate) > new Date(acc.date.endDate)
      ? stayDate
      : acc;
  });
};

type Props = {
  value: StayDate | null;
  onChange: (date: StayDate | null) => void;
  stayDates: StayDateAvailableJsonldReadStayDateAvailable[];
  bottomComponent?: React.ReactNode;
  focusCalendarPopover: () => void;
};

export const CalendarPicker = ({
  value,
  onChange,
  stayDates,
  bottomComponent,
  focusCalendarPopover,
}: Props) => {
  const { t } = useTranslation('mr');
  const locale = useLanguage();
  const { MobileAndTablet, Desktop } = useViewport();
  const [selectedStayType, setSelectedStayType] =
    useState<StayDateAvailableJsonldReadStayDateAvailableStayType>(
      value?.stayType ?? StayDateAvailableJsonldReadStayDateAvailableStayType.wk
    );

  const firstStayDate = findStayDateWithOldestStartDate(stayDates);
  const lastStayDate = findStayDateWithLatestEndDate(stayDates);

  const [selectedMonth, setSelectedMonth] = useState<Date>(
    value?.startDate ??
      (firstStayDate ? new Date(firstStayDate.date.startDate) : new Date())
  );
  const { getDates, getWeekDayNames } = useCalendar();

  const changeStayType = (
    stayType: StayDateAvailableJsonldReadStayDateAvailableStayType
  ) => {
    onChange({
      startDate: null,
      endDate: null,
      stayType,
    });
    setSelectedStayType(stayType);
  };

  const onMonthClick = (month: Date | null) => {
    if (!month) {
      onChange({
        startDate: null,
        endDate: null,
        stayType: selectedStayType,
      });
      return;
    }
    onChange({
      startDate: startOfMonth(month),
      endDate: lastDayOfMonth(month),
      stayType: selectedStayType,
    });
  };

  const getHeaderOfCalendar = (
    selectedDateToDisplay: Date,
    displaySelectors: boolean
  ) => {
    const PreviousNextMonthButton = ({
      dataTestId,
      icon,
      ...props
    }: { dataTestId: string; icon: React.ReactNode } & UIButtonProps) => {
      return (
        <UIButton
          data-testid={dataTestId}
          bgColor="ae.grey_100"
          h="26px"
          w="26px"
          p={0}
          minW={0}
          borderRadius="22px"
          lineHeight="22px"
          {...props}
        >
          {icon}
        </UIButton>
      );
    };

    const isCurrentMonthSelected =
      value &&
      value.startDate &&
      value.endDate &&
      isSameDay(startOfMonth(selectedDateToDisplay), value?.startDate) &&
      isSameDay(lastDayOfMonth(selectedDateToDisplay), value?.endDate);

    return (
      <Flex justifyContent="space-between" h="26px">
        <UIButton
          size="sm"
          variant={isCurrentMonthSelected ? 'secondary' : 'linkUnderlined'}
          ml="6px"
          w="inherit"
          onClick={() =>
            onMonthClick(isCurrentMonthSelected ? null : selectedDateToDisplay)
          }
        >
          {capitalizeFirstLetter(
            formatToBrusselsTz(
              selectedDateToDisplay,
              'LLLL YYY',
              locale as Locale
            )
          )}
        </UIButton>

        {displaySelectors && (
          <HStack>
            <PreviousNextMonthButton
              dataTestId="calendar-picker-previous-month"
              onClick={() => setSelectedMonth(addMonths(selectedMonth, -1))}
              icon={<ChevronLeftIcon size="10px" />}
              disabled={
                firstStayDate?.date.startDate
                  ? isSameMonth(
                      selectedMonth,
                      new Date(firstStayDate?.date.startDate)
                    )
                  : false
              }
            />
            <PreviousNextMonthButton
              dataTestId="calendar-picker-next-month"
              onClick={() => setSelectedMonth(addMonths(selectedMonth, 1))}
              icon={<ChevronRightIcon size="10px" />}
              disabled={
                lastStayDate?.date.endDate
                  ? isSameMonth(
                      selectedMonth,
                      new Date(lastStayDate?.date.endDate)
                    )
                  : false
              }
            />
          </HStack>
        )}
      </Flex>
    );
  };

  const getWeekDaysNamesOfCalendar = (selectedDate: Date) => {
    return (
      <Grid mt="22px" mb="10px" gridTemplateColumns="repeat(7, 50px)">
        {getWeekDayNames(selectedDate).map((weekDayName, i) => (
          <GridItem key={i}>
            <Center
              color={i === 5 || i === 6 ? 'ae.grey_300' : 'ae.grey_400'}
              fontSize="14px"
            >
              <Text>{weekDayName}</Text>
            </Center>
          </GridItem>
        ))}
      </Grid>
    );
  };

  const getDatesOfCalendar = (selectedMonth: Date) => {
    const filteredIntervals: StayDate[] = stayDates
      .filter((stayDate) => stayDate.stayType === selectedStayType)
      .map(
        (stayDate) =>
          ({
            id: stayDate.id,
            startDate: parseISO(stayDate.date.startDate),
            endDate: parseISO(stayDate.date.endDate),
            stayType: stayDate.stayType,
          } as StayDate)
      );

    return (
      <Grid
        gridTemplateColumns="repeat(7, 50px)"
        gridTemplateRows="repeat(5, 38px)"
        rowGap="6px"
      >
        {getDates(selectedMonth).map((week) =>
          week.map((day, i) => (
            <CalendarDay
              key={i}
              day={day}
              selectedMonth={selectedMonth}
              selectedInterval={value}
              intervals={filteredIntervals}
              onClick={onChange}
              focusCalendarPopover={focusCalendarPopover}
            />
          ))
        )}
      </Grid>
    );
  };

  const MonthSelect = ({
    w,
    mb,
  }: {
    w?: number | string;
    mb?: number | string;
  }) => {
    const options = stayDates
      .map((stayDate) => {
        return {
          value: startOfMonth(parseISO(stayDate?.date?.startDate)),
          label: formatToBrusselsTz(
            startOfMonth(parseISO(stayDate?.date?.startDate)),
            'LLLL YYY',
            locale as Locale
          ),
          year: formatToBrusselsTz(
            startOfMonth(parseISO(stayDate?.date?.startDate)),
            'yyyy',
            locale as Locale
          ),
        };
      })
      .filter((v, i, arr) => arr.findIndex((t) => t.label === v.label) === i)
      .sort((a, b) => a.value.getTime() - b.value.getTime());

    const groupedOptions = options.reduce(
      (acc: { [key: string]: typeof options }, option) => {
        if (!acc[option.year]) {
          acc[option.year] = [];
        }
        acc[option.year].push(option);
        return acc;
      },
      {}
    );

    return (
      <UISelectBox
        dataTestId="calendar-picker-month-select"
        aria-label={t('mr1.date.month_selection_aria')}
        w={w}
        mb={mb}
        value={selectedMonth.toISOString()}
        onChange={(e) => setSelectedMonth(parseISO(e.target.value))}
      >
        {Object.keys(groupedOptions).map((year) => (
          <optgroup key={year} label={year}>
            {groupedOptions[year].map(({ value, label }) => (
              <option key={label} value={value.toISOString()}>
                {capitalizeFirstLetter(label)}
              </option>
            ))}
          </optgroup>
        ))}
      </UISelectBox>
    );
  };

  const StayTypeButtons = () => {
    const StayTypeButton = ({
      label,
      type,
    }: {
      label: string;
      type: StayDateAvailableJsonldReadStayDateAvailableStayType;
    }) => (
      <UIButton
        variant={selectedStayType === type ? 'secondary' : 'tertiary'}
        size="sm"
        label={label}
        w="auto"
        onClick={() => changeStayType(type)}
      />
    );

    return (
      <HStack gap="10px">
        {Object.keys(StayDateAvailableJsonldReadStayDateAvailableStayType)
          .filter(
            (key) =>
              key !==
              StayDateAvailableJsonldReadStayDateAvailableStayType.custom
          )
          .map((key) => (
            <StayTypeButton
              key={key}
              label={t(
                stayTypeLabels[
                  key as keyof typeof StayDateAvailableJsonldReadStayDateAvailableStayType
                ]
              )}
              type={
                StayDateAvailableJsonldReadStayDateAvailableStayType[
                  key as keyof typeof StayDateAvailableJsonldReadStayDateAvailableStayType
                ]
              }
            />
          ))}
      </HStack>
    );
  };

  const Legend = () => (
    <HStack spacing="20px" mb="20px">
      <HStack>
        <Center
          h="18px"
          w="18px"
          borderRadius="18px"
          boxShadow="0 2px 5px rgba(0,0,0,.15)"
        >
          <Text color="ae.green" fontSize="12px">
            2
          </Text>
        </Center>
        <Text fontSize="12px">{t('mr1.arrival_day')}</Text>
      </HStack>
      <HStack>
        <Box h="10px" w="20px" borderRadius="20px" bgColor="ae.green" />
        <Text fontSize="12px">{t('mr1.chosen_stays')}</Text>
      </HStack>
      <HStack>
        <Box h="10px" w="20px" borderRadius="20px" bgColor="ae.pink" />
        <Text fontSize="12px">{t('mr1.available_stays')}</Text>
      </HStack>
    </HStack>
  );

  return (
    <Box data-testid="calendar-picker">
      <MobileAndTablet>
        <UISelectBox
          aria-label={t('mr1.date.staytype_selection_aria')}
          onChange={(e) =>
            changeStayType(
              e.target
                .value as keyof typeof StayDateAvailableJsonldReadStayDateAvailableStayType
            )
          }
        >
          {Object.keys(StayDateAvailableJsonldReadStayDateAvailableStayType)
            .filter(
              (key) =>
                key !==
                StayDateAvailableJsonldReadStayDateAvailableStayType.custom
            )
            .map((key) => (
              <option key={key} value={key}>
                {t(
                  stayTypeLabels[
                    key as keyof typeof StayDateAvailableJsonldReadStayDateAvailableStayType
                  ]
                )}
              </option>
            ))}
        </UISelectBox>

        <MonthSelect mb="20px" />

        {getHeaderOfCalendar(selectedMonth, true)}

        <VStack mb="20px">
          {getWeekDaysNamesOfCalendar(selectedMonth)}
          {getDatesOfCalendar(selectedMonth)}
        </VStack>

        <Legend />
        {bottomComponent}
      </MobileAndTablet>

      <Desktop>
        <Box w="900px">
          <Flex justifyContent="space-between" mb="36px">
            <StayTypeButtons />
            <MonthSelect w="200px" mb={0} />
          </Flex>

          <Grid templateColumns="repeat(2, 1fr)" gap="20px" mb="24px">
            <GridItem>
              {getHeaderOfCalendar(selectedMonth, false)}
              {getWeekDaysNamesOfCalendar(selectedMonth)}
              {getDatesOfCalendar(selectedMonth)}
            </GridItem>
            <GridItem>
              {getHeaderOfCalendar(addMonths(selectedMonth, 1), true)}
              {getWeekDaysNamesOfCalendar(addMonths(selectedMonth, 1))}
              {getDatesOfCalendar(addMonths(selectedMonth, 1))}
            </GridItem>
          </Grid>

          <Legend />
          {bottomComponent}
        </Box>
      </Desktop>
    </Box>
  );
};
