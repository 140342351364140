import { useTranslation } from '@ae/shared';

export enum Mr2Filters {
  TriRecherche,
  Comfort,
  Bathrooms,
  Rooms,
  Distance,
  Discount,
  New,
  RentalVilla,
  RentalCastle,
  RentalExceptional,
  RentalCottage,
  RentalHouse,
  RentalChalet,
  RentalFarm,
  RentalBusiness,
  RelaxIndoorPool,
  RelaxOutdoorPool,
  RelaxSauna,
  RelaxHamam,
  RelaxJacuzzi,
  TypeCouples,
  TypeKids,
  TypeTeenagers,
  TypeGroups,
  TypeDogs,
  TypeQuality,
  TypeLevelAccess,
  TypeWeelchair,
  KidsPlayground,
  KidsChair,
  KidsBabybed,
  KidsBabypark,
  KidsDiaperMattress,
  KidsMicrowave,
  EquipmentBedlinen,
  EquipmentGames,
  EquipmentInternet,
  EquipmentFireplace,
  EquipmentWashingmachine,
  EquipmentMeetingroom,
  EquipmentChargingPoint,
  ProximityLake,
  ProximityWater,
  TypeFishing,
  TypeBike,
  DeerSlab,
  Christmas,
  Isolated,
  EntreSambreMeuseNationalPark,
  SemoisValleyNationalPark,
  NiceView,
  IdealBabies,
  IdealKids,
  Entertainment,
  FilterRentalLuxus,
  FilterRentalBestScore,
  FilterEquipmentBathTowel,
  FilterEquipmentKicker,
  FilterEquipmentBilliard,
  FilterEquipmentPrivateGarden,
  FilterEquipmentBarbecue,
  FilterEquipmentPrivateTerrace,
  FilterEquipmentPrivateParking,
  FilterIdealBusinessStay,
  FilterNoDog,
}

const mr2FiltersTradMapping: {
  [x in Mr2Filters]: string;
} = {
  [Mr2Filters.TriRecherche]: '',
  [Mr2Filters.Comfort]: 'mr2.filter_comfort',
  [Mr2Filters.Bathrooms]: 'mr2.filter_bathrooms',
  [Mr2Filters.Rooms]: 'mr2.filter_rooms',
  [Mr2Filters.Distance]: 'mr2.filter_distance',
  [Mr2Filters.Christmas]: 'mr2.filter_christmas',
  [Mr2Filters.Discount]: 'mr2.filter_discount',
  [Mr2Filters.New]: 'mr2.filter_new',
  [Mr2Filters.RentalVilla]: 'mr2.filter_rental_villa',
  [Mr2Filters.RentalCastle]: 'mr2.filter_rental_castle',
  [Mr2Filters.RentalExceptional]: 'mr2.filter_rental_exceptional',
  [Mr2Filters.RentalCottage]: 'mr2.filter_rental_cottage',
  [Mr2Filters.RentalHouse]: 'mr2.filter_rental_house',
  [Mr2Filters.RentalChalet]: 'mr2.filter_rental_chalet',
  [Mr2Filters.RentalFarm]: 'mr2.filter_rental_farm',
  [Mr2Filters.RentalBusiness]: 'mr2.filter_rental_business',
  [Mr2Filters.RelaxIndoorPool]: 'mr2.filter_relax_indoor_pool',
  [Mr2Filters.RelaxOutdoorPool]: 'mr2.filter_relax_outdoor_pool',
  [Mr2Filters.RelaxSauna]: 'mr2.filter_relax_sauna',
  [Mr2Filters.RelaxHamam]: 'mr2.filter_relax_hamam',
  [Mr2Filters.RelaxJacuzzi]: 'mr2.filter_relax_jacuzzi',
  [Mr2Filters.TypeCouples]: 'mr2.filter_type_couples',
  [Mr2Filters.TypeKids]: 'mr2.filter_type_kids',
  [Mr2Filters.TypeTeenagers]: 'mr2.filter_type_teenagers',
  [Mr2Filters.TypeGroups]: 'mr2.filter_type_groups',
  [Mr2Filters.TypeDogs]: 'mr2.filter_type_dogs',
  [Mr2Filters.TypeQuality]: 'mr2.filter_type_quality',
  [Mr2Filters.TypeLevelAccess]: 'mr2.filter_type_level_access',
  [Mr2Filters.TypeWeelchair]: 'mr2.filter_type_weelchair',
  [Mr2Filters.KidsPlayground]: 'mr2.filter_kids_playground',
  [Mr2Filters.KidsChair]: 'mr2.filter_kids_chair',
  [Mr2Filters.KidsBabybed]: 'mr2.filter_kids_babybed',
  [Mr2Filters.KidsBabypark]: 'mr2.filter_kids_babypark',
  [Mr2Filters.KidsDiaperMattress]: 'mr2.filter_kids_diaper_mattress',
  [Mr2Filters.KidsMicrowave]: 'mr2.filter_kids_microwave',
  [Mr2Filters.EquipmentBedlinen]: 'mr2.filter_equipment_bedlinen',
  [Mr2Filters.EquipmentGames]: 'mr2.filter_equipment_games',
  [Mr2Filters.EquipmentInternet]: 'mr2.filter_equipment_internet',
  [Mr2Filters.EquipmentFireplace]: 'mr2.filter_equipment_fireplace',
  [Mr2Filters.EquipmentWashingmachine]: 'mr2.filter_equipment_washingmachine',
  [Mr2Filters.EquipmentMeetingroom]: 'mr2.filter_equipment_meetingroom',
  [Mr2Filters.EquipmentChargingPoint]: 'mr2.filter_equipment_charging_point',
  [Mr2Filters.ProximityLake]: 'mr2.filter_proximity_lake',
  [Mr2Filters.ProximityWater]: 'mr2.filter_proximity_water',
  [Mr2Filters.TypeFishing]: 'mr2.filter_type_fishing',
  [Mr2Filters.TypeBike]: 'mr2.filter_type_bike',
  [Mr2Filters.DeerSlab]: 'mr2.filter_deer_slab',
  [Mr2Filters.Isolated]: 'mr2.filter_isolated',
  [Mr2Filters.EntreSambreMeuseNationalPark]:
    'mr2.filter_entre_sambre_meuse_national_park',
  [Mr2Filters.SemoisValleyNationalPark]:
    'mr2.filter_semois_valley_national_park',
  [Mr2Filters.NiceView]: 'mr2.filter_nice_view',
  [Mr2Filters.IdealBabies]: 'mr2.filter_ideal_babies',
  [Mr2Filters.IdealKids]: 'mr2.filter_ideal_kids',
  [Mr2Filters.Entertainment]: 'mr2.filter_entertainment',
  [Mr2Filters.FilterRentalLuxus]: 'mr2.filter_rental_Luxus',
  [Mr2Filters.FilterRentalBestScore]: 'mr2.filter_rental_best_score',
  [Mr2Filters.FilterEquipmentBathTowel]: 'mr2.filter_equipment_bath_towel',
  [Mr2Filters.FilterEquipmentKicker]: 'mr2.filter_equipment_kicker',
  [Mr2Filters.FilterEquipmentBilliard]: 'mr2.filter_equipment_billiard',
  [Mr2Filters.FilterEquipmentPrivateGarden]:
    'mr2.filter_equipment_private_garden',
  [Mr2Filters.FilterEquipmentBarbecue]: 'mr2.filter_equipment_barbecue',
  [Mr2Filters.FilterEquipmentPrivateTerrace]:
    'mr2.filter_equipment_private_terrace',
  [Mr2Filters.FilterEquipmentPrivateParking]:
    'mr2.filter_equipment_private_parking',
  [Mr2Filters.FilterIdealBusinessStay]: 'mr2.filter_ideal_business_stay',
  [Mr2Filters.FilterNoDog]: 'mr2.filter_no_dog',
};

type Mr2FiltersValues = {
  [filter in Mr2Filters]?: string | boolean;
};

export const useMr2Url = () => {
  const { t } = useTranslation('mr');

  const generateMr2FiltersUrl = (
    values: Mr2FiltersValues,
    lng?: string
  ): string => {
    let generatedUrl = Object.entries(values)
      .filter(([key]) => {
        return (Number(key) as Mr2Filters) !== Mr2Filters.TriRecherche;
      })
      .map(([key, value]) => {
        if (typeof value === 'boolean') {
          return t(mr2FiltersTradMapping[Number(key) as Mr2Filters], { lng });
        } else {
          return `${value}_${t(
            mr2FiltersTradMapping[Number(key) as Mr2Filters],
            { lng }
          )}`;
        }
      })
      .join('/');

    if (values[Mr2Filters.TriRecherche]) {
      generatedUrl += `?TriRecherche=${values[Mr2Filters.TriRecherche]}`;
    }

    return generatedUrl;
  };

  const parseMr2FiltersFromUrl = (): Mr2FiltersValues => {
    // We reload url as it can be changed by legacy on frontend.
    const url = typeof window !== 'undefined' ? window.location.href : '';

    const values: Mr2FiltersValues = {};

    const partTriRecherche = () => {
      const searchParams = new URLSearchParams(
        url.includes('?') ? url.split('?')[1] : ''
      );

      if (searchParams.has('TriRecherche')) {
        values[Mr2Filters.TriRecherche] =
          searchParams.get('TriRecherche') ?? '';
      }
    };

    const parseFilter = (filter: Mr2Filters, pattern: string) => {
      const matches = url?.match(
        new RegExp(`(${pattern})_${t(mr2FiltersTradMapping[filter])}`)
      );
      if (matches) {
        values[filter] = matches[1];
      }
    };

    Object.entries(mr2FiltersTradMapping).forEach((_, value) => {
      switch (value) {
        case Mr2Filters.TriRecherche:
          partTriRecherche();
          break;
        case Mr2Filters.Comfort:
        case Mr2Filters.Distance:
          parseFilter(value as Mr2Filters, '\\d+');
          break;
        case Mr2Filters.Bathrooms:
        case Mr2Filters.Rooms:
          parseFilter(value as Mr2Filters, '\\d+-\\d+');
          break;
        default:
          if (
            url?.includes(
              t(
                mr2FiltersTradMapping[
                  value as keyof typeof mr2FiltersTradMapping
                ]
              )
            )
          ) {
            values[value as Mr2Filters] = true;
          }
      }
    });

    return values;
  };

  return {
    generateMr2FiltersUrl,
    parseMr2FiltersFromUrl,
  };
};
