import { defaultLocale, Locale } from './types';

export const getLocaleFromUrl = (url: string | undefined): Locale => {
  if (url) {
    let matches = url.startsWith('http')
      ? url.match(/^(http|https):\/\/(www\.)?(fr|en|de|nl)/gi)
      : url.match(/^(fr|en|de|nl)/gi);

    if (matches instanceof Array && matches.length) {
      const language = matches[0].match(/(fr|de|nl|en)/gi);
      if (language instanceof Array) {
        return language[0]
          .replace('http://', '')
          .replace('https://', '')
          .replace('.', '') as Locale;
      }
    }

    matches = url.match(/\.(fr|de|nl|co\.uk)/gi);
    if (matches instanceof Array && matches.length) {
      return matches[0].replace('co.uk', 'en').replace('.', '') as Locale; // ltd
    }
  }

  return defaultLocale;
};
