import { UINotificationBullet, Link } from '@ae/shared-ui';
import { Text, Flex, HStack, FlexProps } from '@chakra-ui/react';

import React from 'react';

export type MenuItemProps = {
  href: string;
  hasNotification?: boolean;
  icon: any;
  label: string;
  selected?: boolean;
};

export const UIMenuItem = ({
  href,
  hasNotification = false,
  icon,
  label,
  selected,
  ...props
}: MenuItemProps & FlexProps) => (
  <Link
    href={href}
    color="ae.green"
    w="100%"
    // common header
    _hover={{ color: 'ae.green', textDecoration: 'underline' }}
    _active={{ color: 'ae.green' }}
    _focus={{ color: 'ae.green' }}
  >
    <Flex p="8px" {...props}>
      <HStack>
        <UINotificationBullet bullet={hasNotification}>
          {React.cloneElement(icon, { size: '24px', space: '5px' })}
        </UINotificationBullet>

        <Text fontWeight={selected ? 'extrabold' : 'normal'}>{label}</Text>
      </HStack>
    </Flex>
  </Link>
);

UIMenuItem.displayName = 'UIMenuItem';
