import { useContext } from 'react';
import { ApiContext } from '../providers';
import { getLocaleFromUrl } from './getLocaleFromUrl';
import { beProdDomains, foreignProdDomains } from './types';

export const isBeProdDomain = (host: string) =>
  Object.values(beProdDomains).some((domain) => host?.includes(domain));

export const isForeignProdDomain = (host: string): boolean =>
  Object.values(foreignProdDomains).some((domain) => host?.includes(domain));

export const isProdDomain = (host: string) =>
  isBeProdDomain(host) || isForeignProdDomain(host);

export const getProdDomains = (host: string = getHostFromBrowserOrParam()) =>
  isForeignProdDomain(host) ? foreignProdDomains : beProdDomains;

export const isBrowser = () => typeof window !== 'undefined';

export const getHostFromBrowserOrParam = (host: string = '') =>
  isBrowser() ? `${window.location.protocol}//${window.location.host}` : host;

export const prodDomains = getProdDomains(getHostFromBrowserOrParam());

export const useApiDomain = (host: string = getHostFromBrowserOrParam()) => {
  const { baseApi, apiDomain } = useContext(ApiContext);
  return `${
    apiDomain ?? getProdDomains(host)[getLocaleFromUrl(host)]
  }${baseApi}`;
};
