import { StandaloneComponent } from './StandaloneComponent';
import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Footer } from '@ae/shared-comp';
import { standaloneComponentProps } from '../types';

const FooterStandaloneComponent = () => {
  const {
    footer: { hideHelpCenter },
  } = standaloneComponentProps;

  return (
    <Router>
      <StandaloneComponent appName="footer">
        <Footer hideHelpCenter={hideHelpCenter} />
      </StandaloneComponent>
    </Router>
  );
};

const container = document.getElementById('footer');
if (container) {
  const root = createRoot(container);
  root.render(<FooterStandaloneComponent />);
}
