import { useMemo } from 'react';
import {
  isSameDay,
  isWithinInterval,
  addDays,
  startOfMonth,
  endOfMonth,
} from 'date-fns';
import { StayDate } from '@ae/shared-comp';

enum Day {
  Yesterday = -1,
  Today = 0,
  Tomorrow = 1,
}

const getFirstOrNull = (intervals: StayDate[]) =>
  intervals.length ? intervals[0] : null;

const intervalsOnDay = (intervals: StayDate[], day: Date, relativeDay: Day) =>
  intervals.filter((interval) => {
    if (!interval?.startDate || !interval?.endDate) {
      return false;
    }
    return isWithinInterval(addDays(day, relativeDay), {
      start: interval.startDate,
      end: interval.endDate,
    });
  });

export const useInterval = (
  day: Date,
  selectedInterval: StayDate | null | undefined,
  intervals: StayDate[]
) => {
  const intervalsOnToday = useMemo(
    () => intervalsOnDay(intervals, day, Day.Today),
    [day, intervals]
  );
  const intervalsOnDayBefore = useMemo(
    () => intervalsOnDay(intervalsOnToday, day, Day.Yesterday),
    [day, intervalsOnToday]
  );
  const intervalsOnDayAfter = useMemo(
    () => intervalsOnDay(intervalsOnToday, day, Day.Tomorrow),
    [day, intervalsOnToday]
  );

  const intervalOnToday = getFirstOrNull(intervalsOnToday);
  const intervalOnDayBefore = getFirstOrNull(intervalsOnDayBefore);
  const intervalOnDayAfter = getFirstOrNull(intervalsOnDayAfter);

  const selectedIntervalOnToday = selectedInterval
    ? isWithinInterval(day, {
        start: selectedInterval?.startDate ?? new Date(),
        end: selectedInterval?.endDate ?? new Date(),
      })
    : false;
  const selectedIntervalOnDayBefore =
    selectedInterval &&
    !isSameDay(
      addDays(day, Day.Yesterday),
      selectedInterval?.endDate ?? new Date()
    )
      ? isWithinInterval(addDays(day, Day.Yesterday), {
          start: selectedInterval?.startDate ?? new Date(),
          end: selectedInterval?.endDate ?? new Date(),
        })
      : false;
  const selectedIntervalOnDayAfter = selectedInterval
    ? isWithinInterval(addDays(day, Day.Tomorrow), {
        start: selectedInterval?.startDate ?? new Date(),
        end: selectedInterval?.endDate ?? new Date(),
      }) &&
      !isSameDay(
        addDays(day, Day.Tomorrow),
        selectedInterval?.startDate ?? new Date()
      )
    : false;

  const intervalsStartingOnDay = intervalsOnDay(
    intervalsOnToday,
    day,
    Day.Today
  ).filter((interval) => isSameDay(interval?.startDate ?? new Date(), day));

  const nearestIntervalOnToday = intervalsOnToday.length
    ? intervalsOnToday.sort((a, b) =>
        (a?.startDate ?? '') < (b?.startDate ?? '')
          ? Day.Tomorrow
          : Day.Yesterday
      )[0]
    : null;

  const isFirstDayOfInterval =
    (intervalOnToday?.startDate &&
      isSameDay(intervalOnToday?.startDate, day)) ??
    false;

  const isMonthSelected = (() => {
    if (
      !selectedInterval ||
      selectedInterval.startDate === null ||
      selectedInterval.endDate === null
    ) {
      return false;
    }
    const firstDayOfMonth = startOfMonth(selectedInterval.startDate);
    const lastDayOfMonth = endOfMonth(selectedInterval.endDate);

    return (
      isSameDay(selectedInterval.endDate, lastDayOfMonth) &&
      isSameDay(selectedInterval.startDate, firstDayOfMonth)
    );
  })();

  return {
    intervalsOnToday,
    intervalOnToday,
    intervalOnDayBefore,
    intervalOnDayAfter,
    selectedIntervalOnToday,
    selectedIntervalOnDayBefore,
    selectedIntervalOnDayAfter,
    intervalsStartingOnDay,
    nearestIntervalOnToday,
    isFirstDayOfInterval,
    isMonthSelected,
  };
};
