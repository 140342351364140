import { startOfWeek, startOfMonth, format, addDays } from 'date-fns';

export const useCalendar = () => {
  const getWeekDayNames = (selectedDate: Date) => {
    const weekStartDate = startOfWeek(selectedDate, { weekStartsOn: 1 });

    return [...Array.from({ length: 7 })].map((_, i) =>
      format(addDays(weekStartDate, i), 'EEE').toUpperCase()
    );
  };

  const getDates = (selectedDate: Date) => {
    const startOfTheSelectedMonth = startOfMonth(selectedDate);
    const startDate = startOfWeek(startOfTheSelectedMonth, { weekStartsOn: 1 });

    const getDatesOfWeek = (date: Date) => {
      return [...Array.from({ length: 7 })].map((_, i) => addDays(date, i));
    };

    return [...Array.from({ length: 6 })].map((_, i) =>
      getDatesOfWeek(addDays(startDate, 7 * i))
    );
  };

  return {
    getWeekDayNames,
    getDates,
  };
};
