import { defaultLocale, LanguageContext } from '@ae/i18n';
import { useCallback, useContext } from 'react';
import { useSearchUrlTranslation } from '../Mr';
import { useRouter } from '@ae/shared';

export const useChangeLocale = () => {
  const { getSearchUrl } = useSearchUrlTranslation();
  const { pathname } = useRouter();
  const { changeLocale } = useContext(LanguageContext);

  const defaultChangeLocale = useCallback(
    (newLocale: string) => {
      const host = window.location.host
        .replace('www.', '')
        .replace(/(fr|en|de|nl)\./, '')
        .replace(/\.(fr|en|de|nl)/, '.be');
      const hostPrefix = newLocale === defaultLocale ? 'www' : newLocale;
      const domain = `${window.location.protocol}//${hostPrefix}.${host}`;

      // // Temporary redirect waiting for url translation.
      // const searchPaths = {
      //   [Locale.Fr]: '/recherche',
      //   [Locale.Nl]: '/zoeken',
      //   [Locale.De]: '/suchen',
      //   [Locale.En]: '/search',
      // };
      //
      // const searchPath = searchPaths[newLocale as Locale];
      //
      // if (pathname?.includes(searchPaths[locale as Locale])) {
      //   const searchUrl = getSearchUrl(newLocale as Locale);
      //   window.location.href = `${domain}${searchPath}${searchUrl}`;
      //   return;
      // }

      const { search } = new URL(window.location.href);
      window.location.href = `${domain}${
        pathname === '/' ? '' : pathname
      }${search}`;
    },
    [pathname, getSearchUrl]
  );

  return { changeLocale: changeLocale || defaultChangeLocale };
};
