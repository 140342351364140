import { format, isSameDay, isSameMonth } from 'date-fns';
import {
  Box,
  Text,
  Center,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { UIRadioButton } from '@ae/shared-ui';
import { formatToBrusselsTz, useTranslation } from '@ae/shared';
import { useInterval } from './useInterval';
import { StayDate } from '@ae/shared-comp';
import { useLanguage, Locale } from '@ae/i18n';

const sortIntervalsOnEndDate = (intervals: StayDate[]) =>
  intervals.sort((a: StayDate, b: StayDate) => {
    return (a?.endDate?.getTime() ?? 0) - (b?.endDate?.getTime() ?? 0);
  });

type Props = {
  day: Date;
  selectedMonth: Date;
  selectedInterval: StayDate | null | undefined;
  intervals: StayDate[];
  onClick: (interval: StayDate | null) => void;
  focusCalendarPopover: () => void;
};

export const CalendarDay = ({
  day,
  selectedMonth,
  selectedInterval,
  intervals,
  onClick,
  focusCalendarPopover,
}: Props) => {
  const {
    intervalOnToday,
    intervalOnDayBefore,
    intervalOnDayAfter,
    selectedIntervalOnToday,
    intervalsStartingOnDay,
    nearestIntervalOnToday,
    isFirstDayOfInterval,
    selectedIntervalOnDayBefore,
    selectedIntervalOnDayAfter,
    isMonthSelected,
  } = useInterval(day, selectedInterval, intervals);
  const language = useLanguage();
  const { t } = useTranslation('mr');
  const { isOpen, onToggle, onClose } = useDisclosure({
    onClose: focusCalendarPopover,
  });

  const isLeftDayGreen =
    !isMonthSelected && selectedIntervalOnToday && selectedIntervalOnDayBefore;
  const isRightDayGreen =
    !isMonthSelected && selectedIntervalOnToday && selectedIntervalOnDayAfter;
  const isDayGreen = !isMonthSelected && selectedIntervalOnToday;
  const isDayPink = !!intervalOnToday;
  const isLeftDayPink = !!intervalOnDayBefore;
  const isRightDayPink = !!intervalOnDayAfter;
  const isWhiteBubble =
    (intervalOnToday?.startDate && isSameDay(intervalOnToday.startDate, day)) ??
    false;

  if (!isSameMonth(day, selectedMonth)) {
    return <Box />;
  }

  return (
    <Center
      data-testid={`calendar-day-${formatToBrusselsTz(
        day,
        'dd-MM-yyyy',
        language as Locale
      )}`}
      position="relative"
      role="button"
      cursor={isFirstDayOfInterval ? 'pointer' : 'initial'}
      onClick={() =>
        isFirstDayOfInterval &&
        intervalsStartingOnDay.length === 1 &&
        onClick(nearestIntervalOnToday)
      }
      style={{ WebkitTapHighlightColor: 'transparent' }}
    >
      <Box
        position="absolute"
        left={0}
        top={0}
        bottom={0}
        w="50%"
        cursor="pointer"
        bgColor={isLeftDayGreen ? 'ae.green' : isLeftDayPink ? 'ae.pink' : ''}
      />

      <Box
        position="absolute"
        right={0}
        top={0}
        bottom={0}
        w="50%"
        cursor="pointer"
        bgColor={isRightDayGreen ? 'ae.green' : isRightDayPink ? 'ae.pink' : ''}
      />

      {intervalsStartingOnDay.length > 1 ? (
        <Popover isOpen={isOpen} onClose={onClose}>
          <PopoverTrigger>
            <Center onClick={onToggle}>
              <DateIndicator
                day={day}
                isDayGreen={isDayGreen}
                isDayPink={isDayPink}
                isWhiteBubble={isWhiteBubble}
              />
            </Center>
          </PopoverTrigger>
          <PopoverContent borderColor="#ffc000">
            <PopoverArrow />
            <PopoverCloseButton />

            <PopoverBody px="20px" py="30px">
              <VStack alignItems="flex-start">
                <Text>{t('mr1.choose_a_stay')}</Text>
                {sortIntervalsOnEndDate(intervalsStartingOnDay).map(
                  (interval) => (
                    <UIRadioButton
                      key={interval.id}
                      label={t('mr1.stay_aria', {
                        start: formatToBrusselsTz(
                          interval?.startDate ?? new Date(),
                          'eee dd MMM',
                          language as Locale
                        ),
                        end: formatToBrusselsTz(
                          interval?.endDate ?? new Date(),
                          'eee dd MMM',
                          language as Locale
                        ),
                      })}
                      value=""
                      isChecked={
                        selectedInterval?.startDate &&
                        selectedInterval?.endDate &&
                        interval?.startDate &&
                        interval?.endDate
                          ? isSameDay(
                              selectedInterval?.startDate,
                              interval.startDate
                            ) &&
                            isSameDay(
                              selectedInterval?.endDate,
                              interval.endDate
                            )
                          : false
                      }
                      variant="round"
                      onChange={() => {
                        onClick(interval);
                        onClose();
                      }}
                    />
                  )
                )}
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      ) : (
        <Box>
          <DateIndicator
            day={day}
            isDayGreen={isDayGreen}
            isDayPink={isDayPink}
            isWhiteBubble={isWhiteBubble}
          />
        </Box>
      )}
    </Center>
  );
};

type DateIndicatorProps = {
  day: Date;
  isDayGreen: boolean;
  isDayPink: boolean;
  isWhiteBubble: boolean;
};

const DateIndicator = ({
  day,
  isDayGreen,
  isDayPink,
  isWhiteBubble,
}: DateIndicatorProps) => {
  return (
    <Center
      position="relative"
      h="38px"
      w="38px"
      borderRadius="38px"
      bgColor={isDayGreen ? 'ae.green' : isDayPink ? 'ae.pink' : ''}
    >
      <Center
        h="30px"
        w="30px"
        borderRadius="24px"
        bgColor={
          isWhiteBubble
            ? 'white'
            : isDayGreen
            ? 'ae.green'
            : isDayPink
            ? 'ae.pink'
            : ''
        }
      >
        <Text
          fontSize="14px"
          fontWeight={isDayGreen ? 'bold' : 'semibold'}
          color={
            isWhiteBubble
              ? 'ae.green'
              : isDayGreen
              ? 'white'
              : isDayPink
              ? 'ae.green'
              : 'ae.grey_300'
          }
        >
          {format(day, 'd')}
        </Text>
      </Center>
    </Center>
  );
};
