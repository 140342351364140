export const checkboxTheme = {
  baseStyle: {
    control: {
      bg: 'white',
      border: '1px solid',
      borderColor: 'ae.green',
      borderRadius: '3px',
      _invalid: {
        borderColor: 'ae.red',
        border: '2px solid',
        color: 'ae.red',
      },
      _checked: {
        bg: 'ae.pink',
        borderColor: 'ae.green',
      },
    },
  },
};
