import { format } from 'date-fns';
import {
  StayDateAvailableJsonldReadStayDateAvailableStayType,
  useApiLocalitiesGetCollection,
} from '@ae/data-access';
import { SearchValues } from '@ae/shared-comp';

export const useContextualDataLayer = () => {
  const { data: localities } = useApiLocalitiesGetCollection();

  const getDataLayer = (
    searchValues: SearchValues,
    dataLayer: any = {}
  ): any => {
    if (searchValues.composition) {
      if (searchValues.composition.persons) {
        dataLayer.booking_number_people = searchValues.composition.persons;

        const persons = searchValues.composition.persons;
        if (persons >= 2 && persons < 3) {
          dataLayer.booking_capacity = `2-3`;
        } else if (persons >= 4 && persons <= 5) {
          dataLayer.booking_capacity = `4-5`;
        } else if (persons >= 6 && persons <= 7) {
          dataLayer.booking_capacity = `6-7`;
        } else if (persons === 8) {
          dataLayer.booking_capacity = `8-8`;
        } else if (persons === 9) {
          dataLayer.booking_capacity = `9-9`;
        } else if (persons >= 10 && persons < 12) {
          dataLayer.booking_capacity = `10-12`;
        } else if (persons >= 13 && persons < 15) {
          dataLayer.booking_capacity = `13-15`;
        } else if (persons >= 16 && persons <= 20) {
          dataLayer.booking_capacity = `16-20`;
        } else if (persons >= 21 && persons <= 24) {
          dataLayer.booking_capacity = `21-24`;
        } else if (persons >= 25 && persons <= 29) {
          dataLayer.booking_capacity = `25-29`;
        } else if (persons >= 30 && persons <= 34) {
          dataLayer.booking_capacity = `30-34`;
        } else if (persons >= 35) {
          dataLayer.booking_capacity = `35+`;
        }
      }
      if (searchValues.composition.dogs) {
        dataLayer.booking_dog = searchValues.composition.dogs;
      }
    }
    if (searchValues.stayDate) {
      if (searchValues.stayDate.startDate) {
        dataLayer.booking_outbound_date = format(
          searchValues.stayDate.startDate,
          'dd-MM-yyyy'
        );
        dataLayer.booking_period =
          new Date(searchValues.stayDate.startDate).toLocaleString('default', {
            month: 'long',
          }) +
          ' ' +
          new Date(searchValues.stayDate.startDate).getFullYear();
        dataLayer.booking_booking_date = format(
          searchValues.stayDate.startDate,
          'dd-MM-yyyy'
        );
      }
      if (searchValues.stayDate.endDate) {
        dataLayer.booking_inbound_date = format(
          searchValues.stayDate.endDate,
          'dd-MM-yyyy'
        );
      }
      if (searchValues.stayDate.startDate && searchValues.stayDate.endDate) {
        dataLayer.booking_length_of_stay = Math.ceil(
          (new Date(searchValues.stayDate.endDate).getTime() -
            new Date(searchValues.stayDate.startDate).getTime()) /
            (1000 * 60 * 60 * 24)
        );
      }
      if (searchValues.stayDate.stayType) {
        switch (searchValues.stayDate.stayType) {
          case StayDateAvailableJsonldReadStayDateAvailableStayType.wk:
            dataLayer.booking_holiday_break = 'weekend';
            break;
          case StayDateAvailableJsonldReadStayDateAvailableStayType.lw:
            dataLayer.booking_holiday_break = 'long_weekend';
            break;
          case StayDateAvailableJsonldReadStayDateAvailableStayType.mw:
            dataLayer.booking_holiday_break = 'midweek';
            break;
          case StayDateAvailableJsonldReadStayDateAvailableStayType['1w']:
            dataLayer.booking_holiday_break = '2_weeks';
            break;
          case StayDateAvailableJsonldReadStayDateAvailableStayType['2w']:
            dataLayer.booking_holiday_break = '2_weeks';
            break;
          case StayDateAvailableJsonldReadStayDateAvailableStayType.custom:
            dataLayer.booking_holiday_break = 'custom';
            break;
        }
      }
    }
    if (searchValues.localityId) {
      dataLayer.booking_location = localities?.['hydra:member'].find(
        (l) => l.id === searchValues.localityId
      )?.name;
    }

    if (searchValues.stayDate && searchValues.stayDate.startDate) {
      const today = new Date();
      const startDate = new Date(searchValues.stayDate.startDate);
      const timeDiff = startDate.getTime() - today.getTime();
      dataLayer.booking_horizon = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    }

    return dataLayer;
  };

  const updateDataLayer = (data: SearchValues) => {
    const dataLayer = JSON.parse(
      localStorage.getItem('ContextualDataLayer') ?? '{}'
    );

    // dataLayer is mutated by reference.
    getDataLayer(data, dataLayer);

    localStorage.setItem(
      'ContextualDataLayer',
      JSON.stringify({ ...dataLayer })
    );
  };

  return {
    updateDataLayer,
    getDataLayer,
  };
};
