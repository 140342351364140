import { useCallback, useEffect } from 'react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useLanguage } from '@ae/i18n';

import 'dayjs/locale/fr';
import 'dayjs/locale/nl';
import 'dayjs/locale/en';
import 'dayjs/locale/de';

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export const useDate = () => {
  const language = useLanguage();

  useEffect(() => {
    dayjs.locale(language);
  }, [language]);

  const formatDate = useCallback(
    (date: string | Date): string =>
      dayjs(date).tz('Europe/Brussels').format('L').replace(/\//g, '.'),
    []
  );

  const formatDateCustom = useCallback(
    (date: string | Date, format = 'L'): string =>
      dayjs(date).tz('Europe/Brussels').format(format),
    []
  );

  // current date in ISO8601, without fraction seconds e.g. '2020-04-02T08:02:17-05:00'
  const formatDateIso = (date: string | Date): string =>
    dayjs(date).tz('Europe/Brussels').format();

  const formatDateWithoutYear = useCallback(
    (date: string | Date): string =>
      formatDate(date).replace(/(\.|-)\d{4}/, ''),
    [formatDate]
  );

  return {
    formatDate,
    formatDateWithoutYear,
    formatDateIso,
    formatDateCustom,
    dayjs,
  };
};
