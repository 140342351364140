/* eslint-disable @nx/enforce-module-boundaries */
import { getLocaleFromUrl, supportedLngs } from '@ae/i18n';
import i18n, { LanguageDetectorModule } from 'i18next';
import { initReactI18next } from 'react-i18next';

type Resources = { [x: string]: { [x: string]: string } };

const namespaces = ['common', 'footer', 'header', 'mr', 'profile', 'favorites'];
const resources: Resources = {};

for (const lng of supportedLngs) {
  resources[lng] = {};

  for (const ns of namespaces) {
    try {
      resources[lng][
        ns
      ] = require(`../../../libs/i18n/public/locales/${lng}/${ns}.json`);
    } catch (error) {
      console.error(
        `Failed to load translation file: /locales/${lng}/${ns}.json`,
        error
      );
    }
  }
}

const languageDetector: LanguageDetectorModule = {
  type: 'languageDetector',
  detect: () => getLocaleFromUrl(window.location.href),
};

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    resources,
    ns: namespaces,
    defaultNS: 'common',
    detection: {
      order: ['subdomain'],
    },
    supportedLngs,
    react: {
      useSuspense: false,
    },
  });

export default i18n;
