import { useLanguage } from '@ae/i18n';
import { useCallback } from 'react';

import { useApiBookingsGetCollection } from '@ae/data-access';
import { LinkGetter, useGetDomain, useLink, useMe } from '../hooks';
import { useDate, useTranslation } from '@ae/shared';

export enum ThematicLink {
  New,
  HotTub,
  Redu,
  Best,
  Chalets,
  Dog,
  PoolTable,
  Swimmingpool,
  Sauna,
  UnusualAccommodations,
  Castles,
  ByTheWater,
  Autumn,
  DeerRutting,
  View,
  NewYear,
  Jacuzzi,
}

export const useGetThematicLink = () => {
  const lng = useLanguage();
  const { dayjs } = useDate();
  const { t } = useTranslation('thematic');
  const { prodDomain, apiDomain, getBeProdDomain } = useGetDomain();
  const { authenticated } = useMe();
  const { data: bookings } = useApiBookingsGetCollection({
    query: { enabled: authenticated },
  });

  const getLink = useCallback(
    (link: ThematicLink): LinkGetter => {
      switch (link) {
        case ThematicLink.New:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/nouveautes`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/nieuw`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/neu`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/new`;
                default:
                  return '';
              }
            },

            alt: t('thematic.popular.new.label'),
            title: t('thematic.popular.new.label'),
          };

        case ThematicLink.Jacuzzi:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/Vacances-Jacuzzi`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/Vakantie-Jacuzzi`;
                case 'de':
                  return `${prodDomain}/holiday-houses-ardennes/Holidays-Jacuzzi`;
                case 'en':
                  return `${prodDomain}/ferienhauser-ardennen/Ferien-Jacuzzi`;
                default:
                  return '';
              }
            },

            alt: t('thematic.popular.jacuzyThematic.label'),
            title: t('thematic.popular.jacuzyThematic.label'),
          };

        case ThematicLink.View:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/terrasse-vue-imprenable`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/terras-met-uniek-uitzicht`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/terrasse-blick`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/terrace-breathtaking-view`;
                default:
                  return '';
              }
            },

            alt: t('thematic.popular.view.label'),
            title: t('thematic.popular.view.label'),
          };

        case ThematicLink.NewYear:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/Nouvel-An`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/Nieuwjaar`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/Neujahr`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/New-Year`;
                default:
                  return '';
              }
            },

            alt: t('thematic.popular.newYear.label'),
            title: t('thematic.popular.newYear.label'),
          };

        case ThematicLink.DeerRutting:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/ecouter-brame-du-cerf`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/luisteren-naar-burlende-herten`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/hirschrohren`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/listening-to-the-belling-of-deer`;
                default:
                  return '';
              }
            },

            alt: t('thematic.popular.deerRutting.label'),
            title: t('thematic.popular.deerRutting.label'),
          };

        case ThematicLink.Autumn:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/automne`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/herfst`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/herbst`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/autumn`;
                default:
                  return '';
              }
            },
          };

        case ThematicLink.HotTub:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/Vacances-Jacuzzi`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/Vakantie-Jacuzzi`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/Ferien-Jacuzzi`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/Holidays-Jacuzzi`;
                default:
                  return '';
              }
            },
            alt: t('thematic.popular.hotTub.label'),
            title: t('thematic.popular.hotTub.label'),
          };
        case ThematicLink.Redu:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/Redu?TriRecherche=Distance-ASC`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/Redu?TriRecherche=Distance-ASC`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/Redu?TriRecherche=Distance-ASC`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/Redu?TriRecherche=Distance-ASC`;
                default:
                  return '';
              }
            },
            alt: t('thematic.popular.redu.label'),
            title: t('thematic.popular.redu.label'),
          };
        case ThematicLink.Best:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/maisons-de-vacances-les-mieux-notees`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/de-best-beoordeelde-vakantiehuizen`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/die-hauser-mit-den-besten-bewertungen`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/the-best-rated-holiday-homes`;
                default:
                  return '';
              }
            },
            alt: t('thematic.popular.best.label'),
            title: t('thematic.popular.best.label'),
          };
        case ThematicLink.Chalets:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/Chalets`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/Chalets`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/Chalets`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/Chalets`;
                default:
                  return '';
              }
            },
            alt: t('thematic.popular.chalet.label'),
            title: t('thematic.popular.chalet.label'),
          };
        case ThematicLink.Dog:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/vacances-avec-chien`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/vakantie-met-hond`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/urlaub-mit-hund`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/pet-friendly-accommodation`;
                default:
                  return '';
              }
            },
            alt: t('thematic.popular.dog.label'),
            title: t('thematic.popular.dog.label'),
          };
        case ThematicLink.PoolTable:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/recherche/1-99p/Week-end/0000-00-00/billard/?TriRecherche=Satisfaction-DESC`;
                case 'nl':
                  return `${prodDomain}/zoeken/1-99p/Weekend/0000-00-00/biljart/?TriRecherche=Satisfaction-DESC`;
                case 'de':
                  return `${prodDomain}/suchen/1-99p/Wochenende/0000-00-00/billardtisch/?TriRecherche=Satisfaction-DESC`;
                case 'en':
                  return `${prodDomain}/search/1-99p/Weekend/0000-00-00/billiard/?TriRecherche=Satisfaction-DESC`;
                default:
                  return '';
              }
            },
            alt: t('thematic.popular.poolTable.label'),
            title: t('thematic.popular.poolTable.label'),
          };
        case ThematicLink.Swimmingpool:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/gites-avec-piscine`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/vakantiehuizen-met-zwembad`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/ferienhauser-mit-schwimmbad`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/holiday-houses-with-swimmingpool`;
                default:
                  return '';
              }
            },
            alt: t('thematic.wellness.swimmingpool.label'),
            title: t('thematic.wellness.swimmingpool.label'),
          };
        case ThematicLink.Sauna:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/Sejour-avec-Sauna`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/Verblijven-met-Sauna`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/Aufenthalt-mit-Sauna`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/Stays-with-Sauna`;
                default:
                  return '';
              }
            },
            alt: t('thematic.wellness.sauna.label'),
            title: t('thematic.wellness.sauna.label'),
          };
        case ThematicLink.UnusualAccommodations:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/week-end-insolite`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/origneel-overnachten`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/originell-ubernachten`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/original-accommodation`;
                default:
                  return '';
              }
            },
            alt: t('thematic.wellness.unusualAccommodations.label'),
            title: t('thematic.wellness.unusualAccommodations.label'),
          };
        case ThematicLink.Castles:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/chateaux`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/kastelen`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/schlosser`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/castles`;
                default:
                  return '';
              }
            },
            alt: t('thematic.wellness.castles.label'),
            title: t('thematic.wellness.castles.label'),
          };
        case ThematicLink.ByTheWater:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/bord-eau`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/huizen-aan-het-water`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/am-wasser`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/by-the-water`;
                default:
                  return '';
              }
            },
            alt: t('thematic.campagne.title'),
            title: t('thematic.campagne.title'),
          };

        default:
          throw Error('link not found');
      }
    },
    [
      apiDomain,
      authenticated,
      bookings,
      dayjs,
      getBeProdDomain,
      lng,
      prodDomain,
      t,
    ]
  );

  return useLink<ThematicLink>(getLink);
};
