import { StandaloneComponent } from '../StandaloneComponent';
import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { RentalFavoritesAuthentication } from './RentalFavoritesAuthentication';

const RentalFavoritesStandaloneComponent = () => {
  return (
    <Router>
      <StandaloneComponent appName="favorites">
        <RentalFavoritesAuthentication />
      </StandaloneComponent>
    </Router>
  );
};

const container = document.getElementById('favorites');
if (container) {
  const root = createRoot(container);
  root.render(<RentalFavoritesStandaloneComponent />);
}
