import { createContext, ReactNode, useEffect } from 'react';
import TagManager from 'react-gtm-module';

export const getGtmSectionByUrl = (
  url: string = typeof window !== 'undefined' ? window.location.href : ''
) => {
  switch (true) {
    case /\/guest/.test(url):
    case /\/guest-staging/.test(url):
    case /localhost:3000/.test(url):
    case /loc.ardennes-etape.com:3000/.test(url):
      return 'client_account';
    case /\/experience\//.test(url):
    case /\/experience-preprod\//.test(url):
      return 'aex';
    default: {
      return 'ecommerce';
    }
  }
};

export const GlobalTagContext = createContext({
  email_address: '',
  user_id: '',
  page_language: '',
  login_status: '',
  page_location: '',
  website_section: '',
});

type Props = {
  language: string;
  userId: number | undefined;
  userEmail: string | undefined;
  children: ReactNode;
};

export const TagProvider = ({
  language,
  userId,
  userEmail,
  children,
}: Props) => {
  useEffect(() => {
    const gtmId = process.env['NX_GTM_ID'] ?? '';
    if (gtmId) {
      TagManager.initialize({
        gtmId,
      });
    }
  }, []);

  return (
    <GlobalTagContext.Provider
      value={{
        email_address: userEmail ?? '',
        user_id: `${userId}` ?? '',
        page_language: language,
        login_status: userEmail ? 'logged_in' : 'guest',
        page_location:
          typeof window !== 'undefined' ? window.location.href : '',
        website_section: getGtmSectionByUrl(),
      }}
    >
      {children}
    </GlobalTagContext.Provider>
  );
};
