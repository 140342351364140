import {
  Checkbox as BaseCheckbox,
  CheckboxProps,
  forwardRef,
  Text,
} from '@chakra-ui/react';

type Props = {
  label?: string | React.ReactNode;
  value?: string;
  defaultIsChecked?: boolean;
  error?: string;
};

export const UICheckbox = forwardRef<Props & CheckboxProps, 'input'>(
  ({ label, value, defaultIsChecked = true, error, ...others }, ref) => (
    <BaseCheckbox
      ref={ref}
      defaultChecked={defaultIsChecked}
      colorScheme="checkBox"
      iconColor="ae.green"
      size={others.size || 'lg'}
      isInvalid={!!error}
      value={value}
      {...others}
    >
      <Text
        fontSize={others.fontSize ?? '14px'}
        color={others.color ?? 'ae.black'}
      >
        {label}
      </Text>
      {error && (
        <Text color="ae.red" fontSize="14px">
          {error}
        </Text>
      )}
    </BaseCheckbox>
  )
);
