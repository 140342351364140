import { StandaloneComponent } from './StandaloneComponent';
import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Header } from '@ae/shared-comp';
import { standaloneComponentProps } from '../types';

const HeaderStandaloneComponent = () => {
  const {
    onUserLoginChange,
    header: { hideBackground, showMr1 },
  } = standaloneComponentProps;

  return (
    <Router>
      <StandaloneComponent appName="header">
        <Header
          hideBackground={hideBackground}
          showMr1={showMr1}
          onUserLoginStatusChange={onUserLoginChange}
        />
      </StandaloneComponent>
    </Router>
  );
};

const container = document.getElementById('header');

console.log('helloooo');

if (container) {
  const root = createRoot(container);
  root.render(<HeaderStandaloneComponent />);
}
