import { Text } from '@chakra-ui/react';

export const UIFormLabel = ({
  label,
  isRequired = false,
}: {
  label: string;
  isRequired?: boolean;
}) => (
  <Text fontWeight="bold" mb="5px" fontSize="14px">
    {label}
    {isRequired ? ' *' : ''}
  </Text>
);
