export const switchTheme = {
  baseStyle: {
    userSelect: 'none',
    outline: 'none',
    container: {},
    thumb: {
      userSelect: 'none',
      bg: 'ae.white',
      _checked: {
        bg: 'ae.white',
      },
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.15)',
    },
    track: {
      userSelect: 'none',
      bg: 'ae.grey_300',
      _checked: {
        bg: 'ae.green',
      },
      _focus: {
        boxShadow: 'none',
      },
    },
  },
  sizes: {
    md: {
      container: {},
      track: {
        height: '20px',
        width: '40px',
      },
      thumb: {
        position: 'absolute',
        height: '14px',
        width: '14px',
        left: '8px',
        top: '5px',
      },
    },
  },
};
