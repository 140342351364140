export const buttonTheme = {
  baseStyle: {
    size: 'lg',
    bgColor: 'none',
    w: { base: 'full', lg: 'auto' },
    color: 'ae.green',
    border: '0',
    borderColor: 'none',
    fontWeight: 'bold',
    wordBreak: 'break-word',
    transition: 'all 0.3s cubic-bezier(.08,.52,.52,1)',
    whiteSpace: 'normal',
    _disabled: {
      bgColor: 'ae.grey_300',
      color: 'white',
    },
    _hover: {
      boxShadow: '0px 5px 15px 0px rgba(0, 0, 0, 0.1)',
      _disabled: {
        bgColor: 'ae.grey_300',
        color: 'white',
      },
    },
  },

  sizes: {
    sm: {
      h: 'auto',
      fontSize: '16px',
      p: '7px 20px',
      fontWeight: '600',
      borderRadius: '12px',
    },
    md: {
      h: 'auto',
      fontSize: '18px',
      p: '14px 25px',
      borderRadius: 'button',
    },
    lg: {
      h: 'auto',
      fontSize: '18px',
      p: '20px 40px',
      borderRadius: 'button',
    },
  },

  variants: {
    primary: {
      bgColor: 'ae.pink',
    },

    secondary: {
      border: 'none',
      bgColor: 'ae.green',
      color: 'white',
      _hover: {
        bgColor: 'ae.green_600',
      },
    },

    tertiary: {
      border: '1px solid',
      borderColor: 'ae.green',
      _hover: {
        bgColor: 'ae.green_400',
        color: 'ae.green',
        borderColor: 'ae.green_400',
      },
    },

    danger: {
      border: '1px solid',
      borderColor: 'error.900',
      color: 'error.900',
      _hover: {
        bgColor: 'error.100',
        color: 'error.900',
        borderColor: 'error.900',
      },
    },

    linkDanger: {
      w: 'fit-content',
      p: '0',
      border: 'none',
      color: 'error.900',
      h: 'auto',
      _hover: {
        boxShadow: 'none',
        textDecoration: 'underline',
      },
    },

    linkDangerUnderlined: {
      w: 'fit-content',
      p: '0',
      border: 'none',
      color: 'error.900',
      h: 'auto',
      textDecoration: 'underline',
      _hover: {
        boxShadow: 'none',
        textDecoration: 'underline',
      },
    },

    link: {
      w: 'fit-content',
      p: '0',
      border: 'none',
      color: 'ae.green',
      h: 'auto',
      _hover: {
        boxShadow: 'none',
        textDecoration: 'underline',
      },
    },

    linkUnderlined: {
      w: 'fit-content',
      p: '0',
      border: 'none',
      color: 'ae.green',
      h: 'auto',
      textDecoration: 'underline',
      _hover: {
        boxShadow: 'none',
        textDecoration: 'underline',
      },
    },

    iconOutlined: {
      outline: 'none',
      w: 'auto',
      h: 'none',
      p: '0px 0px',
      border: '1px solid',
      borderColor: '#C4C4C499',
      borderRadius: '20px',
      bg: 'ae.transparent',
      _hover: {
        bgColor: 'ae.transparent',
      },
      _active: {
        bgColor: 'ae.transparent',
      },
      _selected: {
        bgColor: 'ae.transparent',
      },
      _focus: {
        bgColor: 'ae.transparent',
      },
    },
  },
};
